const dev = {
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_KEY: "pk_test_hN34pSV2nPGLF6ccZFv8INnE00BnTQBbAp",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-dev-attachmentsbucket-15ashzahu7eji",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.swrpg-fleet-mgr.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_ulf9PRpsi",
    APP_CLIENT_ID: "3aosc6c515oe0v57llqufjjo96",
    IDENTITY_POOL_ID: "us-east-1:03ac86c9-e16c-4359-b179-9a418536bba1",
  },
};
const prod = {
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_KEY: "pk_test_hN34pSV2nPGLF6ccZFv8INnE00BnTQBbAp",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-z5zhcplm6atd",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.swrpg-fleet-mgr.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_a9Eg0CJft",
    APP_CLIENT_ID: "2kgnm5ho3tb05mf9l79jjmaq8b",
    IDENTITY_POOL_ID: "us-east-1:57a5e91b-6c85-47d5-a8c0-f0e243d96ca6",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
